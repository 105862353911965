/* AppNavigator.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Poppins', sans-serif;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
    padding-bottom: 20px; /* Ensure there's space for the footer */
}

.footer {
    background-color: #101522; /* Dark background for contrast */
    color: white;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-top: 10px;
    width: 100%;
    position: relative;
}

.footer-upper, .footer-lower {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
}

.footer-logo .logo {
    width: 75px; /* Adjust size as needed */
}

.footer-links {
    display: flex;
    font-size: 1rem;
}

.footer-link {
    color: white; /* Accent color */
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.13s;
}

.footer-link:hover {
    color: gray; /* Hover effect */
}

.email-link, .number-link {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
}

.social-media .icon {
    font-size: 24px;
    color: white;
    margin: 0 10px;
    transition: transform 0.2s ease-in;
}

.social-media .icon:hover {
    transform: translateY(-2px);
}

.contact-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer-bottom {
    font-size: 11px;
    margin-top: 20px;
    border-top: 1px solid #101522; /* Subtle separator */
    padding-top: 10px;
}

@media (max-width: 768px) {
    .footer-upper, .footer-lower {
        flex-direction: column;
        align-items: center;
    }

    .footer-links, .social-media {
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
    }

    .footer-link, .social-media .icon {
        margin: 5px 0;
    }

    .contact-info {
        margin: 5px 0;
    }

    .footer-logo .logo {
        width: 90px; /* Slightly smaller logo for tablet */
    }

    .social-media .icon {
        font-size: 20px; /* Slightly smaller icons for better spacing */
        margin: 10px;
    }
}

@media (max-width: 480px) {
    .footer-logo .logo {
        width: 85px; /* Further reduced size for mobile */
    }

    .social-media .icon {
        font-size: 18px; /* Further reduced icon size for mobile */
    }

    .footer-link {
        font-size: 14px; /* Adjust font size for better readability */
    }

    .footer-bottom {
        font-size: 12px; /* Smaller font size for the bottom text */
    }

    .contact-info p {
        font-size: 14px; /* Adjust contact info font size for readability */
    }
}
