.track-order-container {
    padding: 20px;
    padding-top: 100px;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    max-width: 800px;
  }
  
  .track-order-container h1 {
    margin-bottom: 20px;
  }
  
  .track-order-container p {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .track-order-header {
    text-align: center;
  }