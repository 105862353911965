.dispensary-container {
    padding: 20px;
    padding-top: 100px;
    max-width: 800px;
    margin: 40px auto;
    border-radius: 10px;
  }

 .products-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
 } 
  
  .dispensary-title {
    font-size: 28px;
    font-weight: bold;
    color: #101522;
    margin: 0;
  }
  
  .category-container {
    margin-bottom: 20spx;
    border-bottom: 2px solid black;
  }
  
  .category-card {
    cursor: pointer;
    border-radius: 20px 20px 0 0;
    border: 1px solid #101522;
    background-color: #101522;
    color: white;
    min-width: 150px;
    font-weight: bold;
    transition: 0.2s ease-in;
    flex-wrap: nowrap;
  }
  
  .category-card:hover {
    transition: 0.2s ease-in;
    color: #101522;
    background-color: #f5f5f5;
    box-shadow: 0 6px 8px rgba(0,0,0,0.1);
    border: 1px solid #101522;
  }

  .category-card.active {
    background-color: #f5f5f5;
    color: #101522;
    border: 1px solid #101522;
  }
  
  .category-name {
    font-size: 16px;
    /* margin-left: 10px; */
    text-align: center;
  }
  
  .category-image {
    width: 60px;
    height: 60px;
    border-radius: 30px; /* Circular images */
  }
  
  .product-card {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    background-color: white;
    border-radius: 0px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
  
  .product-name, .product-thc, .product-price {
    margin-left: 15px;
    overflow: visible !important;
  }
  
  .product-image {
    width: 80px;
    height: 80px;
    border-radius: 0px;
  }
  
  .subtitle {
    font-size: 22px;
    font-weight: 600;
    color: #101522;
    margin-bottom: 15px;
    text-align: left;
  }
  
  .scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 10px; /* Spacing between category cards */
  }

  @media screen and (max-width: 768px){
    .products-container {
      grid-template-columns: 1fr 1fr;
     } 
  }

  @media screen and (max-width: 480px){
    .products-container {
      grid-template-columns: 1fr;
     } 
  }
  