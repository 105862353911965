.address-container {
    padding: 20px;
    max-width: 600px;
    margin: 40px auto;
    border-radius: 10px;
    padding-top: 100px;
  }
  
  .address-header {
    font-size: 24px;
    font-weight: bold;
    color: #101522;
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .address-input {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 0px;
    resize: none; /* Optional: if you want to prevent resizing */
  }
  
  .address-button {
    background-color: #101522;
    padding: 15px;
    border-radius: 0px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    width: 100%;
    border: none;
    transition: background-color 0.3s;
  }
  
  .address-button:hover {
    background-color: gray; 
  }
  