
.bottom-nav-container {
  position: relative;
  overflow-x: hidden; /* Add this to prevent horizontal scrolling */
}
.search-bar-container {
  background-color: #f1f1f1;
  border-radius: 33px;
  /* margin-left: 10px; */
  position: relative; /* Needed for absolute positioning of children */
  width: 100%; /* Adjust as needed */
  /* Other styles... */
}

.product-search {
  border-radius: 33px;
  width: 100%; /* Adjust width as needed */
}

.search-results-dropdown {
  position: absolute;
  top: 100%; /* Start the dropdown right below the search bar */
  left: 0;
  width: 100%; /* Match the width with the search bar */
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  z-index: 10; /* Ensures it's above other content but not covering the search bar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
  max-height: 300px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Allows scrolling within the dropdown */
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.search-result-item:hover {
   transition: 0.2s ease-in;
  background-color: #f1f1f1;
}

.logo {
  margin: 15px;
  color: white;
  font-size: 24px;
}

.bottom-nav-bar {
  background-color: #101522;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  max-width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.logo-link .logo-image {
  height: 54px; /* Smaller logo for mobile */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: none !important;
  margin: 10px;
}

.link-container {
  display: flex;
  gap: 15px; /* Reduced gap for mobile */
  margin: 10px auto;
  align-items: center;
  justify-content: center;
}

.nav-link{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
}

.nav-link:hover, user-icon:hover, .cart-icon:hover{
  transition: 0.2 ease;
  color: gray;
}

.icon-container {
  display: flex;
  padding-right: 20px; /* Less padding for mobile */
  margin: 10px;
}

.search-address-container {
  left: -1px;
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  top: 75px;
  width: 100%;
  max-width: 100%;
  z-index: 999;
  height: 40px;
}

/* Search Bar Style */
.search-bar {
  display: flex;
  align-items: center;
}

.product-search {
  width: 100%;
  border: none;
  background: none;
  opacity: 0.7;
  width: 100%; 
  flex-grow: 1;
}

/* Address Bar Style */
.search-bar-container, .address-bar {
  background-color: #f3f3f3; 
  border-radius: 3px; 
  width: 100%; 
  display: flex; 
  flex-grow: 1; /* Allows the address bar to grow but within limits */
  height: 100%;
}

.input-address {
  flex-grow: 1; 
  width: 100%;
  border: none;
  border: none !important;
  border-radius: 3px;
  border-style: none;
  background-color: #f3f3f3;
  outline: none;
}

.input-address:focus, .product-search:focus {
  outline: none;
}

.search-icon, .location-icon {
  margin: 10px;
  color: #101522; /* Adjust color as needed */
  font-size: 20px; /* Adjust size as needed */
}

/* button < #location-button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
} */

.current-address {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.icon-link {
  color: black;
  padding: 0 5px;
  font-size: 16px; /* Smaller icons for mobile */
}


.cart-bubble {
  background-color: gray;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -10px;
  left: -5px;
}

.user-icon {
  font-size: 20px;
  /* Additional styles if needed */
}

.track-order-icon {
  font-size: 16px;
  padding: 10px;
}

@media (min-width: 768px) {
  .logo-link .logo-image {
    height: 50px; /* Adjust for tablet */
    text-decoration: none !important;

  }

  .link-container {
    gap: 15px; /* Increased gap for tablet */
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for tablet */
  }

  .icon-link {
    font-size: 18px; /* Larger icons for tablet */
  }

  .cart-icon {
    font-size: 22px;
  }

.user-icon {
  font-size: 20px;
  /* Additional styles if needed */
}

  .search-address-container {
    flex-direction: row;
    top: 72px; /* Adjust for smaller screens */
  }

  .search-bar, .address-bar input {
    width: 50%;

  }

  .address-bar {
    flex-direction: row;

  }
}

@media (min-width: 992px) {
  .logo-link .logo-image {
    height: 55px; /* Adjust for desktop */
    text-decoration: none !important;

  }

  .link-container {
    gap: 20px; /* Increased gap for desktop */
  }

  .nav-link {
    font-size: 18px; /* Adjust font size for desktop */
  }

  .icon-link {
    font-size: 20px; /* Larger icons for desktop */
  }

  .cart-icon, .user-icon {
    font-size: 23px;
  }
}

@media (min-width: 1200px) {
  .logo-link .logo-image {
    height: 70px; /* Adjust for large desktop */
    text-decoration: none !important;
  }

  .bottom-nav-bar {
    width: 100%;
  }
  .search-address-container {
    width: 50%;
    left: auto; 
    top: auto; 
    position: static; 
  }
  .search-bar-container, .address-bar {
    margin-right: 10px;
  }
}

  @media (max-width: 480px) {

    .cart-icon {
      font-size: 19px;
    }
    .search-bar, .address-bar input {
      width: 50%;
    }
    .logo {
      font-size: 19px;
    }
  }

