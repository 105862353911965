.dispensaries-container {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .search-bar {
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    /* border: 1px solid gray; */
    border-radius: 33px;
    padding: 15px;
    margin: 20px 0;
    font-size: 16px;
  }
  
  .dispensaries-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    padding-top: 15px;
  }
  
  .dispensary-card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  
  .dispensary-card:hover {
    transform: scale(1.03);
  }
  
  .dispensary-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .dispensary-info {
    padding: 10px;
  }
  
  .dispensary-title h2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .dispensary-rating, .dispensary-type {
    font-size: 14px;
    color: #555;
    margin: 0;
  }
  

  /* Responsive Design Adjustments */
@media screen and (max-width: 768px) {
  .dispensaries-grid {
    grid-template-columns: 1fr; /* Even smaller min-width for smaller screens */
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .search-input {
    width: 90%; /* Full width for smaller screens */
  }
}
  