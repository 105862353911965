.profile-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    padding-top: 130px;
  }
  
  .user-name {
    font-size: 24px;
    font-weight: bold;
    color: #101522;
    text-align: right;
    margin-bottom: 30px;
  }
  
  .profile-section {
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .section-title {
    font-size: 20px;
    font-weight: bold;
    color: #101522;
    margin-bottom: 15px;
  }
  
  .profile-item {
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    color: #101522;
    transition: color 0.3s;
  }
  
  .profile-item:hover {
    color: gray;
  }
  
  .logout-button {
    text-align: center;
    margin-top: 30px;
  }
  
  .logout-button button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .logout-button button:hover {
    background-color: gray;
  }
  
  /* Add any additional specific styles for responsiveness or other elements */
  