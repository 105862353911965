.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 20px auto;
  border-radius: 0px;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.productImage {
  width: 100%;
  height: auto;
  border-radius: 0px;
  max-width: 500px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.productName {
  font-size: 24px;
  font-weight: bold;
  color: #101522;
  text-align: center;
  margin-bottom: 10px;
}

.thcPercentage {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.dispensaryName {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.stepper {
  margin-bottom: 20px;
}

.addToCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #101522;
  color: white;
  padding: 12px 30px;
  border-radius: 0px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
}

.addToCartButton:hover {
  background-color: gray;
}

.buttonText {
  margin-left: 10px;
}

.cartIcon {
  font-size: 20px;
}
