.age-verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay for more focus on modal */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 15px; /* Softened edges */
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
  width: 90%;
  max-width: 450px; /* Responsive width */
  position: relative;
  overflow: hidden; /* Ensure the blur effect is contained within borders */
}

.modal-content::before {
  content: '';
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  background: white;
  background-size: 400% 400%;
  z-index: -1;
  filter: blur(10px);
  border-radius: 20px; /* Larger radius for background glow */
  animation: animateBg 15s linear infinite;
}

@keyframes animateBg {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}

.modal-title {
  font-size: 25px;
  color: #101522;
  margin-bottom: 30px;
  font-weight: 500;
}

.modal-button {
  border: none;
  border-radius: 0px;
  padding: 12px 30px;
  margin: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease;
  color: white;
  background-color: #101522;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.modal-button:hover {
  background-color: gray;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}
