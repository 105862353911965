.cart-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  border-radius: 0px;
}

.cart-header, .section-header {
  font-size: 24px;
  font-weight: bold;
  color: #101522;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 125px;
}

.dispensary-name {
  font-size: 18px;
  font-style: italic;
  color: #555;
  text-align: center;
  margin-bottom: 15px;
}

.item-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: white;
  padding: 10px;
  border-radius: 0px;
}

.item-details {
  display: flex;
  align-items: center;
  flex: 1;
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}

.item-text {
  margin-left: 15px;
}

.item-name {
  font-weight: bold;
  color: #101522;
}

.remove-button {
  background-color: #101522;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: gray;
}

.promo-code-section {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.promo-input {
  flex: 1;
  padding: 8px 12px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.promo-button {
  background-color: #101522;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 8px 12px;
  cursor: pointer;
}

.promo-button:hover {
  background-color: gray;
}

.continue-button {
  font-size: 14px;
  border-radius: 0px;
  width: 100%;
  background-color: #101522;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  margin-top: 20px;
}

.quantity-controls {
  display: flex;
}

.adjust-quantity-button {
  width: 25px;
  height: 25px;
  background: #f1f1f1;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: none;
  border-radius: 0px;
}

.continue-button:hover {
  background-color: gray;   
}

.product-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/* New styles for empty cart message and button */
.empty-cart {
  text-align: center;
  margin-top: 50px;
}

.empty-cart p {
  font-size: 20px;
  color: #555;
}

.shop-now-button {
  background-color: #101522;
  color: white;
  border: none;
  border-radius: 0px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.shop-now-button:hover {
  background-color: gray;
}
