.checkout-container {
    padding: 20px;
    padding-top: 150px;
    max-width: 800px;
    margin: auto;
    border-radius: 0px;
  }
  
  .checkout-header {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
  
  .card-element {
    margin: 10px 0;
    font-size: 20px;
    height: 100px;
    /* Additional styles for the card element */
  }
  .address-header h2{
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
  }

  .addres-input h2{
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
  }
  
  .address-text {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .place-order-button, .track-order-button {
    background-color: #101522;
    color: white;
    padding: 15px;
    border-radius: 0px;
    text-align: center;
    margin-top: 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .place-order-button:hover, .track-order-button:hover {
    background-color: gray;
  }
  
  .input-field {
    width: 100%; /* Full width */
    padding: 15px; /* Comfortable padding */
    margin-bottom: 15px; /* Space between the fields */
    border: 2px solid #ddd; /* Subtle border */
    border-radius: 0px; /* Rounded corners */
    font-size: 16px; /* Readable text size */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    transition: border-color 0.3s; /* Smooth transition for interactions */
  }
  
  .input-field:focus {
    outline: none; /* Remove default focus outline */
    border-color: #ff6d6d; /* Highlight color on focus */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2), 0 0 8px rgba(255, 109, 109, 0.6); /* More pronounced shadow on focus */
  }
  
  /* Additional styles for placeholders */
  .input-field::placeholder {
    color: #999; /* Placeholder text color */
    opacity: 1; /* Ensure it's always visible */
  }
  
  /* Add any additional specific styles for responsiveness or other elements */
  