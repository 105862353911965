.stepper-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

.stepper-btn {
    background-color: #ddd;
    padding: 10px;
    border-radius: 0px;
    cursor: pointer;
    border: none;
    margin: 5px;
}

.stepper-btn-text {
    font-size: 16px;
    font-weight: bold;
}

.qty-text {
    margin: 0 20px;
    font-size: 16px;
}