.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    margin: 0 auto;
}

.input {
    border-bottom: 1px solid black;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
}

.label {
    font-size: 16px;
    color: black;
    margin-top: 10px;
    align-self: flex-start;
}

.signin-text {
    background: none;
    border: none;
    margin-top: 20px;
    color: #101522;
    cursor: pointer;
    font-size: 12px
}

.logo-container {
    margin-bottom: 10px;
}

.toke-logo {
    width: 200px;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
}

.signup-btn {
    width: 200px;
    background-color: #101522;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    border-radius: 0px;
    color: white;
    font-weight: bold;
    border: none;
}

.signup-btn:hover {
    background-color: gray;
}

.decree {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 11px;
}

.google-btn {
    margin-top: 20px;
    border: none;
    cursor: pointer;
    background: none;
}

.google-logo {
    background: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
}