.order-confirmation-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.order-confirmation-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.confirmation-message {
  text-align: center;
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
}

.order-details {
  margin-bottom: 20px;
}

.order-details h2, .order-details h3, .order-details h4 {
  color: #333;
  margin-bottom: 10px;
}

.order-details p {
  margin: 5px 0;
  color: #555;
}

.items-list {
  list-style: none;
  padding: 0;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin-right: 10px;
}

.item-info p {
  margin: 0;
}

.track-order-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.track-order-button:hover {
  background-color: #0056b3;
}
