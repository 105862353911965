.privacy-container {
    font-family: 'Arial', sans-serif;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
  }
  
  .privacy-container h1 {
    padding-top: 120px;
    color: #101522;
    text-align: center;
  }
  
  .privacy-container h2 {
    color: #FF6D6D;
    margin-top: 20px;
  }
  
  .privacy-container p {
    color: #555;
    line-height: 1.6;
    text-align: left;
  }
  li {
    font-weight: normal!important;
  }
  
  .privacy-container a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .privacy-container a:hover {
    text-decoration: underline;
  }
  