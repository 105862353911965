.home-container {
    font-family: 'Poppins', sans-serif;
    color: #101522;
    /* padding-top: 75px; */
}


.hero-section .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.hero-section button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px;
    background-color: #101522;
    color: white;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.hero-section button:hover {
    background-color: gray;
}

.faq-accordian {
  font-weight: 300;
  font-size: 14px;
}

.accordian-title {
  font-weight: 300;
  font-size: 15px;
}

.user-name {
  font-size: 14px;
  color: #101522;
  text-align: center;
  margin-bottom: 10px;
}

h2 a {
  color: #101522; /* Or your preferred color */
  opacity: 0.7;
  text-decoration: none;
  font-size: 0.8rem;
  margin-left: 10px; /* Adds space between the title and the link */
}

h2 a:hover {
  text-decoration: underline;
}

.home-container, .info-container, .horizontal-scroll-container {
    overflow: visible; /* Make sure it's not set to 'hidden' */
  }
  
.hero-section {
  width: 100%;
  display: flex;
  align-items: center;
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  position: relative;
  background-image: url('../assets/hero.png');
  min-height: 485px; /* Adjust the height as needed */
}

.accordion-title {
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 300;
  font-size: 15px;  
}
/* Add this to your CSS file */

.item-added-popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #ff6d6d;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.item-added-popup.show {
  opacity: 1;
  transform: translateY(0);
}


.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.59); 
  z-index: 1; 
}

.text-container {
  opacity: 1 !important;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
    max-width: 60%;
    z-index: 2;
}

.header {
  /* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
  font-family: 'Poppins', sans-serif;
  fontweight: 700;
  opacity: 1;
  color: white;
  font-size: 44px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 10px;
  /* margin: 0 auto; */
  /* font-weight: 650; */
  /* margin: 5px 0; */

}

.subheader {
   font-family: 'Poppins', sans-serif;
   font-weight: 300;
  opacity: 1;
  color: white;
    font-size: 18px;
  text-align: center;
    /* font-weight: 550; */
    padding-top: 5px;
    /* padding-bottom: 5px; */
}

.search-bar {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding-right: 12px;
    /* border-style: none; */
}

.search-input {
  border-radius: 33px !important;
    font-size: 0.85rem;
    padding: 10px 20px;
    border-style: none;
    box-shadow: rgb(247, 247, 247) 0px 0px 0px 1px inset, 0 3px 6px rgba(0,0,0,0.1);
    background-color: rgb(247, 247, 247);
    width: 80%;
}

.location-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    /* url: '/address'; */
    /* padding: 10px; */
    font-size: 1rem;
    color: #ff6d6d;
}

.img-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-img {
    width: 100%;
    height: auto;
}

.info-container {
    padding: 10px;
}

.welcome-container {
    text-align: center;
}


.user-address {
    opacity: 0.7;
    font-weight: 550;
    font-size: 0.75rem;
    color: #fff;
}

.categories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 5px;
    /* margin-bottom: 15px;  */
    /* border-bottom: 2px solid black; */
    position: relative;
    top: 0;
}

.category-button {
    margin-right: 10px;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 20px; /* Simplified notation for uniform border-radius */
    border: none;
    background-color: white;
    color: gray;
    min-width: 100px;
    font-weight: 600;
    transition: background-color 0.12s ease-in, color 0.12s ease-in, transform 0.12s ease-in, box-shadow 0.12s ease-in; /* Transition for multiple properties */
    flex-wrap: nowrap;
    text-align: center; /* Added for better text alignment */
}

.category-button:hover {
    transform: scale(1.05); /* Corrected syntax for transform */
    color: #f1f1f1;
    background-color: #ff6d6d;
    box-shadow: 0 2px 4px rgba(0,0,0,0.12); /* Adjusted box-shadow for subtlety */
}

.category-button.active {
    background-color: #ff6d6d;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0,0,0,0.12); /* Consistent shadow with hover state */
}


.section-title {
    font-size: 20px;
    font-weight: 600;
    padding-top: 5px;
    margin-bottom: 5px;
}

.step-title, .step-description {
  color: #101522;
}

.add-to-cart-button {
    background-color: #101522; /* Green background */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.13s ease-in, transform 0.12s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 5px; /* Space between icon and text */
    font-weight: bold;
    margin-top: 5px; /* Adjust as needed */
  }
  
  .add-to-cart-button:hover {
    background-color: gray;
  }
  
  .add-to-cart-button .cartIcon {
    font-size: 1.2em; /* Larger icon size */
  }
  .accordion-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin: 10px 0;
    border-radius: 0px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: all 0.3s ease-in-out;
  }

.accordion-title {
  font-size: 15px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #101522;
}

.accordion-title:hover {
  color: gray; /* Highlight color */
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordion-item.open .accordion-content {
  max-height: 100px; /* Adjust as needed */
}

.accordion-item.open .icon {
  transform: rotate(180deg);
}

.how-it-works-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 5px;
  padding: 5px;
  text-align: center;
}
/* h3 {
  font-weight: 500;
} */
.how-it-works-step {
  /* font: 14px 'Poppins', sans-serif; */
  padding: 10px;
  background: #f5f5f5;
  border-radius: 0px;
}

/* Add responsive styles if necessary */
@media (max-width: 768px) {
  .how-it-works-container {
    grid-template-columns: 1fr;
  }
}

/* Existing .step-image style */
.step-image {
  width: 40%;
  height: auto;
  border-radius: 0px;
}



/* Media Query for Mobile Devices */
/* @media (max-width: 480px) {
  .step-image {
    width: 30%; /* Adjust this value as needed 
    height: auto;
    /* You can also add other styles here if needed 
  }
} */

.section-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 15px;
}

  .shop-card {
    align-items: center;
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    text-align: left;
    width: 45px !important;
    min-height: 30px;
    margin: 0; /* Resets any margin */
    box-sizing: border-box;
    border-radius: 0px;
    left: -35px;
  }

.product-card, .deal-card, .brand-card {
    justify-content: center;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 200px;
    min-width: 200px;
    min-height: 360px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 13px;
    background-color: rgb(214, 216, 219);
    border-color: black;
    box-shadow: 2px 2px 3px 6px rgba(0,0,0,0.18);
    cursor: pointer;
    transition: transform 0.15s ease-in;
    padding: 5px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.product-card:hover, .deal-card:hover, .brand-card:hover {
    box-shadow: 2px 2px 6px 8px rgba(0,0,0,0.25);
    transform: translate(2s) ease-in-out;
}

.product-container {
  margin: 0 auto;

}

.product-image  {
    width: 250px;
    height: 300px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.product-image img {
  height: 16rem;
  width: 100%;
  /* width: auto; */
 
}

.product-image:hover {
  transform: scale(1.05);
}

 .deal-image, .brand-image {
    width: 250px;
    height: 300px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.product-name, .deal-name, .brand-name {
  font-size: 15px;
  flex-wrap: wrap;
  font-weight: 500;
  overflow: hidden; /* Hide overflow text */
}

.product-thc {
    font-size: 13px;
    /* margin-top: 1px;
    padding-top: 1px; */
    opacity: 0.7;
}

.product-price {
  font-size: 15px;
  font-weight: 400;
  margin-top: 1px;
  display: flex;
  align-items: center; /* Vertically center the flex items */
  margin: 0 auto; /* Center the element within its parent container */
}
.product-shop {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 10px; /* Ensures consistent spacing before the button */
  margin-left: 15px;
  /* align-items: left;
  display: flex;
  justify-content: left; */
  /* margin: 0 auto; */
}

/* Media query for tablets */
@media (max-width: 768px) {
  .home-container {
    font-family: 'Poppins', sans-serif;
    color: #101522;
    padding-top: 62px;
}
    .hero-section {
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding: 20px;
      min-height: 300px; /* Adjusted for tablet */
    }
  
    .text-container, .img-container {
      max-width: 100%;
    }
  
    .header {
      font-family: 'Poppins', sans-serif;
      font-size: 2.2rem;
      font-weight: 500;
    }
  
    .subheader {
      font-size: 18px;
    }
  
    .category-button {
      margin: 6px 8px;  /* reduce margin */
      padding: 5px 5px; /* reduce padding */
      font-size: 12px; /* reduce font size for better fit */
  }
  
    .product-card, .deal-card, .brand-card {
      width: 100%; /* Full width cards on tablet */
    }
  
    .search-input {
      font-size: 14px;
      border-radius: 33px;
    }
  
    .section-title {
       font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    .user-name, .section-title {
      font-size: 12px;
    }
  
    .product-name, .deal-name, .brand-name {
      font-size: 14px;
    }
  
    .product-thc, .product-price {
      font-size: 12px;
    }
  
    /* Adjust slick arrow sizes for better touch target on tablets */
    .slick-prev, .slick-next {
      width: 35px;
      height: 35px;
    }
  
    .slick-prev::before, .slick-next::before {
      font-size: 18px;
    }
  }

  
  /* Media query for MOBILE */
  @media (max-width: 480px) {
   
    .product-image {
      width: 100%;
      height: 300px;
      border-radius: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .hero-section {
      min-height: 270px; /* Further adjusted for mobile */
      align-items: center;
      left: -15px;
      top: -2px;
    }
    .hero-img {
      width: 100%;
      height: auto;
    }
  
    .header {
      text-align: center;
      margin-top: 20px;
      font-size: 26px;
      font-weight: 550;
      padding-bottom: 5px;
    }
    /* .user-name h2{
      font-size: 12px;
      font-weight: 300;
    } */
  
    .subheader {
      font-size: 13px;
      /* margin-bottom: 10px; */
    }

    .how-it-works-step {
      font-size: 13px;
      padding: 12px;
      background: #f5f5f5;
      border-radius: 10px;
  }

  .step-image {
    width: 50%;
    font-size: 12px;
    /* height: auto; */
    border-radius: 0px;
  }

  .product-name {
    font-size: 14px;
  }
  .product-price {
    font-size: 14px;
  }
  .user-name {
    font-size: 12px;
    font-weight: 300;
  }
  
    .category-button {
      margin: 2px; /* further reduce margin */
      margin-top: 0; /* Ensures consistent spacing before the button */
      margin-bottom: 15px;
      padding: 5px 3px; /* further reduce padding */
      font-size: 13px; /* further reduce font size for smaller screens */
      min-width: 100px;
  
  }
  
    .product-card, .deal-card, .brand-card {
      width: auto; /* Adjust for mobile, could be full width or specific width */
    }
  
    .categories-container {
      overflow-x: auto;
      padding-left: 175px;
      left: -15px;
  }

.hero-section button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 15px;
    background-color: #101522;
    color: white;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.section-title {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    padding-top: 5px;
}

.shop-card {
  margin: 0;
}
  
  .faq-accordian p{
    font-weight: 300;
    font-size: 12px;
  }

  .accordian-title {
    font-weight: 300;
    font-size: 11px;
  }
  
}
  